<template>
    <div class="page">
    
    <div>
<h3 class="inpro_heading">IN PROGRESS <h3 class="subtitle">{{ progDataTotal }}</h3> </h3>
<mdb-card-group class="grid_cards">
  <mdb-card class="cards" v-for="packageinProg in inProg">
    <mdb-view hover>
    <mdb-card class="cards-1">
      <div class="name" v-if="packageinProg.users && packageinProg.users.length > 0">{{ packageinProg.users[0].firstName +" "+ packageinProg.users[0].lastName }}</div>
        <div class="xyz">
          <div class="uvx">{{packageinProg.packageNo}}</div>
        </div>
        <div class="cust_inpro" v-if="packageinProg.opsUserDetails && packageinProg.opsUserDetails.length > 0">
            {{packageinProg.opsUserDetails[0].firstName +" "+ packageinProg.opsUserDetails[0].lastName }} <div class="proavatar_inpro">{{ packageinProg.opsUserDetails[0].firstName.charAt(0) }}</div>
        </div>
    </mdb-card>
    </mdb-view>
  </mdb-card>
  </mdb-card-group>
</div>
</div>
</template>
  
  <script>
  import { mdbContainer, mdbRow, mdbCol, mdbCard, mdbCardImage, mdbCardHeader, mdbCardBody, mdbCardTitle, mdbCardText, mdbCardFooter, mdbCardUp, mdbCardAvatar, mdbCardGroup, mdbBtn, mdbView, mdbMask, mdbIcon } from 'mdbvue';
  import { mapMutations, mapActions, mapState } from "vuex";
  export default {
    components: {mdbContainer,mdbRow,mdbCol,mdbCard,mdbCardHeader,mdbCardBody,mdbCardTitle,mdbCardText,mdbCardFooter,
              mdbCardUp,mdbCardAvatar,mdbCardGroup,mdbBtn,mdbView,mdbMask,mdbIcon
    },
    data() {
      return {
        currentDate: new Date()
      };
    },
    mounted() {
      this.progData()
      setTimeout(() => {
      window.location.reload
    }, 35000);
    },
    computed: {
      formattedDateTime() {
        const dateOptions = { day: 'numeric', month: 'short', year: 'numeric' };
        const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: false };
        const datePart = this.currentDate.toLocaleDateString(undefined, dateOptions);
        const timePart = this.currentDate.toLocaleTimeString(undefined, timeOptions);
        return `${datePart} - ${timePart}`;
      },
      ...mapState({
	        inProg: (state) =>{
		      return state.consolidateItems.inProgress;
	      },
        progDataTotal: (state) => {
        return state.consolidateItems.inProgressTotal;
     }
	    }),
    },
    methods:{
      ...mapActions({
		      progDatas:"consolidateItems/inProgressItems"
	    }),
        async progData(){
		    await this.progDatas({limit: 16, shippingStatus: 'Not Initiated', paymentStatus: 'Ready For Payment'})
      },
    },
          
  };
  </script>
    
  <style>
    .page{
      background-color: #ffffff;
      margin-left: 2rem;
      margin-right: 2rem;
    }
    .logo-image {
      top: 0;
      left: 0;
      width: 26rem; 
      height: 8rem;
      padding-left: 3.125rem;
      padding-top: 1.875rem;
      background-color: #ffffff;
      display: flex;
      justify-content: flex-start;
  }
    .date-time-container {
      top: 4rem;
      right: 5.5rem;
      color: #E87613;
      font-family: sans-serif;
      font-size: xx-large;
      width: auto;
      height: auto;
      position:absolute;
  }
    .inpro_heading{
      color: #FAC302;
      font-weight: bolder;
      font-size:x-large;
      padding-left: 3rem;
      padding-top: 1.75rem;
    }
    .subtitle{
    color: #225AA5;
    font-size: x-large;
    display:inline-flex;
    padding-left: 3rem;
    }
    .cards{
      min-width: 24rem;
      width: fit-content;
      height: fit-content;
      background-color: #f8f8f8;
      border-radius: 0.625rem;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      box-shadow: none;
      width: 100%;
    }
    .cards-1{
      width: auto;
      margin: 0 0 0 0;
      padding: 1rem;
      background-color: #ffffff;
      border-radius: 0.625rem;
    }
    .name{
      top: 1.5rem;
      left: 1.25rem;
      font-style: sans-serif;
      color: #000000;
      font-weight: bold;
      font-size: large;
    }
    .xyz{
      display: flex;
      flex-direction: row;
      color: #2763B4;
      font-size: medium;
      font-weight: bold;
      padding-top: 0.5rem;
    }
    .uvx{
      background-color: #F6F5FF;
      border-radius: 0.3rem;
      padding: 0.2rem;
    }
    .xyz>* {
      margin-right: 1rem;
    }
  .cust_inpro{
    font-size: medium;
    align-self: flex-end;
    display: flex;
    align-items: center;
  }
  .proavatar_inpro {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: #4180F1;
    color: white;
    font-size: large;
    margin-left: 0.5rem;
  }   
  .grid_cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background-color: #f8f8f8;
  }
  </style>