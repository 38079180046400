<template>
  <div class="page">
<div>
<h3 class="consolidate_heading">TO BE CONSOLIDATE <h3 class="subtitle">{{ fetchDataTotal }}</h3> </h3>
<mdb-card-group class="grid_cards">
  <mdb-card class="cards" v-for="shippingPackage in toBeConsol">
    <mdb-view hover>
      <mdb-card class="cards-1">
        <div class="name" v-if="shippingPackage.users && shippingPackage.users.length > 0">{{ shippingPackage.users[0].firstName +" "+ shippingPackage.users[0].lastName }}</div>
        <div class="xyz"> 
          <div class="uvx">{{shippingPackage.packageNo}}</div>
        </div>
        <div class="items">
           No.of Items: {{shippingPackage.numberOfItems}}
        </div>
      </mdb-card>
    </mdb-view>
  </mdb-card>
</mdb-card-group>
</div>
</div>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbCard, mdbCardImage, mdbCardHeader, mdbCardBody, mdbCardTitle, mdbCardText, mdbCardFooter, mdbCardUp, mdbCardAvatar, mdbCardGroup, mdbBtn, mdbView, mdbMask, mdbIcon } from 'mdbvue';
import { mapMutations, mapActions, mapState } from "vuex";
export default {
  components: {mdbContainer,mdbRow,mdbCol,mdbCard,mdbCardHeader,mdbCardBody,mdbCardTitle,mdbCardText,mdbCardFooter,
			mdbCardUp,mdbCardAvatar,mdbCardGroup,mdbBtn,mdbView,mdbMask,mdbIcon
	},
  data() {
    return {
      currentDate: new Date()
    };
  },
  mounted() {
    this.fetchData()
  setTimeout(() => {
      window.location.reload
    }, 35000);
  },
  computed: {
    formattedDateTime() {
      const dateOptions = { day: 'numeric', month: 'short', year: 'numeric' };
      const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: false };
      const datePart = this.currentDate.toLocaleDateString(undefined, dateOptions);
      const timePart = this.currentDate.toLocaleTimeString(undefined, timeOptions);
      return `${datePart} - ${timePart}`;
    },
    ...mapState({
	   toBeConsol: (state) =>{
		 return state.consolidateItems.toBeConsolidated;
	   },
     fetchDataTotal: (state) => {
      return state.consolidateItems.toBeConsolidateTotal;
     }
	  }),
  },
  methods:{
    ...mapActions({
		fetchDatas:"consolidateItems/toBeConsolidateItems"
	  }),
    async fetchData(){
		await this.fetchDatas({limit: 16, shippingStatus: 'Not Initiated', paymentStatus: 'Initiated', pagination:'true'})
    },
  },
		
};
</script>
  
<style>
  .page{
    background-color: #ffffff;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .logo-image {
    top: 0;
    left: 0;
    width: 26rem; 
    height: 8rem;
    padding-left: 3.125rem;
    padding-top: 1.875rem;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
}
  .date-time-container {
    top: 4rem;
    right: 5.5rem;
    color: #E87613;
    font-family: sans-serif;
    font-size: xx-large;
    width: auto;
    height: auto;
    position:absolute;
}
  .consolidate_heading{
    color: #225AA5;
    font-weight: bolder;
    font-size:x-large;
    padding-left: 3rem;
    padding-top: 1.75rem;
  }
  .subtitle{
    color: #225AA5;
    font-size: x-large;
    display:inline-flex;
    padding-left: 3rem;
  }
  .cards{
    min-width: 24rem;
    width: fit-content;
    height: fit-content;
    background-color: #f8f8f8;
    padding: 0.3rem;
    padding-right: 1rem;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    box-shadow: none;
    width: 100%;
  }
  .cards-1{
    width: auto;
    margin: 0 0 0 0;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0.625rem;
  }
  .name{
    top: 1.5rem;
    left: 1.25rem;
    font-style: sans-serif;
    color: #000000;
    font-weight: bold;
    font-size: large;
  }
  .xyz{
    display: flex;
    flex-direction: row;
    color: #2763B4;
    font-size: medium;
    font-weight: bold;
  }
  .uvx{
    background-color: #F6F5FF;
    border-radius: 0.3rem;
    padding: 0.2rem;
  }
  .xyz>* {
    margin-right: 1rem;
  }
  .grid_cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background-color: #f8f8f8;
  }
  .items{
    font-size: medium;
    align-self: flex-end;
    align-items: center;
    font-weight: 400;
  }
</style>