<template>

    <div class="page">
    <div>
    <div>
      <img src="../../../../public/YourIndianCart_logo-1-color.jpg" alt="Logo" class="logo-image" />
    </div>
    <div class="date-time-container">
      <p>{{ formattedDate }}</p>
    </div>
    </div>

    <carousel :autoplayHoverPause="false" :per-page="1" :loop="true" :autoplayTimeout="7000" :autoplay="true" :paginationSize="7" paginationActiveColor="black">
    <slide>
     <Status/>
    </slide>
    <slide>
     <Tobeconsolidate/>
    </slide>
    <slide>
     <Inprogress/>
    </slide>
    <slide>
     <Awaiting/>
    </slide>
    <slide>
     <Shipping/>
    </slide>
  
   
  </carousel>


  </div>

</template>

<script>
import Status from './status.vue'
import Tobeconsolidate from './tobeconsolidate.vue';
import Inprogress from './inprogress.vue';
import Awaiting from './awaiting.vue';
import Shipping from './shippingdone.vue';
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide,
    Status,
    Tobeconsolidate,
    Inprogress,
    Awaiting,
    Shipping
  },
  
  data() {
    return {
      currentDate: new Date(),
      formattedDate : "",
      slide: 0,
      sliding: null,
      interval: 3000
    };
    },

  computed: {
    
    },
    mounted() {
      setInterval(() => {
        this.formattedDate = this.formattedDateTime()
        console.log("formated",this.formattedDate)
      }, 1000);
    },
    methods: {
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      },
      formattedDateTime() {
        this.currentDate = new Date()
      const dateOptions = { day: 'numeric', month: 'short', year: 'numeric' };
      const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: false };
      const datePart = this.currentDate.toLocaleDateString(undefined, dateOptions);
      const timePart = this.currentDate.toLocaleTimeString(undefined, timeOptions);
      return `${datePart} - ${timePart}`;
    }
    }

}

</script>

<style>

  .page{
    background-color: #ffffff;
  }
  .logo-image {
    top: 0;
    left: 0;
    width: 26rem; 
    height: 8rem;
    padding-left: 3.125rem;
    padding-top: 1.875rem;
    background-color: #ffffff;
    display: flex;
}
  .date-time-container {
    top: 4rem;
    right: 5.5rem;
    color: #E87613;
    font-family: sans-serif;
    font-size: xx-large;
    width: auto;
    height: auto;
    position:absolute;
}

</style>
