<template>
  <div class="page">
  
  <div>
		<mdb-card-group>
      <mdb-card class="cards_status">
      <mdb-view hover>
      <div>
        <div class="title1"><b>TO BE CONSOLIDATE</b> <h3 class="title1_1">{{ fetchDataTotal }}</h3> </div>
      </div>
      <mdb-card class="cards_1_status" v-for="shippingPackage in toBeConsol">
        <div class="name" v-if="shippingPackage.users && shippingPackage.users.length > 0">{{ shippingPackage.users[0].firstName +" "+ shippingPackage.users[0].lastName }}</div>
        <div class="xyz"> 
          <div class="uvx">{{shippingPackage.packageNo}}</div>
        </div>
        <div class="item_status">
           No.of Items: {{shippingPackage.numberOfItems}}
        </div>
      </mdb-card>
    </mdb-view>
    </mdb-card>
    <mdb-card class="cards_status">
      <mdb-view hover>
      <div>
        <div class="title2"><b>IN PROGRESS</b> <h3 class="title2_2">{{ progDataTotal }}</h3> </div>
      </div>
      <mdb-card class="cards_1_status" v-for="packageinProg in inProg">
        <div class="name" v-if="packageinProg.users && packageinProg.users.length > 0">{{ packageinProg.users[0].firstName +" "+ packageinProg.users[0].lastName }}</div>
        <div class="xyz">
          <div class="uvx">{{packageinProg.packageNo}}</div>
        </div>
        <div class="cust_status" v-if="packageinProg.opsUserDetails && packageinProg.opsUserDetails.length > 0">
            {{packageinProg.opsUserDetails[0].firstName +" "+ packageinProg.opsUserDetails[0].lastName }} <div class="proavatar_status">{{ packageinProg.opsUserDetails[0].firstName.charAt(0)}}</div>
        </div>
      </mdb-card>
    </mdb-view>
    </mdb-card>
    <mdb-card class="cards_status">
      <mdb-view hover>
      <div>
        <div class="title3"><b>AWAITING SHIPMENT</b> <h3 class="title3_3">{{ waitDataTotal }}</h3> </div>
      </div>
      <mdb-card class="cards_1_status" v-for="(packageAwaiting,index) in awaitingShip" :key="index">
        <div class="name" v-if="packageAwaiting.users && packageAwaiting.users.length > 0">{{ packageAwaiting.users[0].firstName +" "+ packageAwaiting.users[0].lastName }}</div>
        <div class="xyz">
          <div class="uvx">{{ packageAwaiting.packageNo }}</div>
        </div>
        <div v-if="packageAwaiting.selectedShippingPartner && packageAwaiting.selectedShippingPartner.length > 0 && packageAwaiting.selectedShippingPartner[0].shippingpartners === 'Fedex'">
          <img
            style=" width: 4rem; height: 2rem; float: right;"
            src="../../../assets/yicimages/fedex.svg" />
        </div>
        <div v-if="packageAwaiting.selectedShippingPartner && packageAwaiting.selectedShippingPartner.length > 0 && packageAwaiting.selectedShippingPartner[0].shippingpartners === 'DHL'">
          <img
            style=" width: 4rem; height: 2rem; float: right;"
            src="../../../assets/yicimages/dhl.svg" />
        </div>
        <div v-if="packageAwaiting.selectedShippingPartner && packageAwaiting.selectedShippingPartner.length > 0 && packageAwaiting.selectedShippingPartner[0].shippingpartners === 'UPS'">
          <img
            style=" width: 4rem; height: 2rem; float: right;"
            src="../../../assets/yicimages/ups.svg" />
        </div>
      </mdb-card>
    </mdb-view>
    </mdb-card>
    <mdb-card class="cards_status">
      <mdb-view hover>
      <div>
        <div class="title4"><b>SHIPMENT DONE</b> <h3 class="title4_4">{{ shipDataTotal }}</h3> </div>  
      </div>
      <mdb-card class="cards_1_status" v-for="packageShip in shipDone">
        <div class="name" v-if="packageShip.users && packageShip.users.length > 0">{{ packageShip.users[0].firstName +" "+ packageShip.users[0].lastName }}</div>
        <div class="xyz">
          <div class="uvx">{{ packageShip.packageNo }}</div>
        </div>
        <div v-if="packageShip.selectedShippingPartner && packageShip.selectedShippingPartner.length > 0 && packageShip.selectedShippingPartner[0].shippingpartners === 'Fedex'">
          <img
            style=" width: 4rem; height: 2rem; float: right;"
            src="../../../assets/yicimages/fedex.svg" />
        </div>
        <div v-if="packageShip.selectedShippingPartner && packageShip.selectedShippingPartner.length > 0 && packageShip.selectedShippingPartner[0].shippingpartners === 'DHL'">
          <img
            style=" width: 4rem; height: 2rem; float: right;"
            src="../../../assets/yicimages/dhl.svg" />
        </div>
        <div v-if="packageShip.selectedShippingPartner && packageShip.selectedShippingPartner.length > 0 && packageShip.selectedShippingPartner[0].shippingpartners === 'UPS'">
          <img
            style=" width: 4rem; height: 2rem; float: right;"
            src="../../../assets/yicimages/ups.svg" />
        </div>
      </mdb-card>
    </mdb-view>
    </mdb-card>
    </mdb-card-group>
  </div>
  </div>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbCard, mdbCardImage, mdbCardHeader, mdbCardBody, mdbCardTitle, mdbCardText, mdbCardFooter, mdbCardUp, mdbCardAvatar, mdbCardGroup, mdbBtn, mdbView, mdbMask, mdbIcon } from 'mdbvue';
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  components: {
  },
  
  data() {
    return {
      currentDate: new Date()
    };
  },
  mounted() {
    this.fetchData(),
    this.progData(),
    this.waitData(),
    this.shipData()
    setTimeout(() => {
      window.location.reload()
    }, 35000);
  },
  computed: {
    formattedDateTime() {
      const dateOptions = { day: 'numeric', month: 'short', year: 'numeric' };
      const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: false };
      const datePart = this.currentDate.toLocaleDateString(undefined, dateOptions);
      const timePart = this.currentDate.toLocaleTimeString(undefined, timeOptions);
      return `${datePart} - ${timePart}`;
    },
    ...mapState({
	   toBeConsol: (state) =>{
		 return state.consolidateItems.status_consol;
	   },
     fetchDataTotal: (state) => {
      return state.consolidateItems.toBeConsolidateTotal;
     }
	  }),
    ...mapState({
	   inProg: (state) =>{
		 return state.consolidateItems.status_inProg;
	   },
     progDataTotal: (state) => {
      return state.consolidateItems.inProgressTotal;
     }
	  }),
    ...mapState({
	   awaitingShip: (state) =>{
		 return state.consolidateItems.status_await;
	   },
     waitDataTotal: (state) => {
      return state.consolidateItems.awaitingShipmentTotal;
     }
	  }),
    ...mapState({
	   shipDone: (state) =>{
		 return state.consolidateItems.status_ship;
	   },
     shipDataTotal: (state) => {
      return state.consolidateItems.shippingDoneTotal;
     }
	  })
  },
  methods:{
    ...mapActions({
		fetchDatas:"consolidateItems/toBeConsolidateItemsStatus",
    
	  }),
      async fetchData(){
		  await this.fetchDatas({limit: 4, shippingStatus: 'Not Initiated', paymentStatus: 'Initiated'})
    },
    ...mapActions({
		progDatas:"consolidateItems/inProgressItemsStatus"
	  }),
    async progData(){
		await this.progDatas({limit: 4, shippingStatus: 'Not Initiated', paymentStatus: 'Ready For Payment'})
    },
    ...mapActions({
		waitDatas:"consolidateItems/awaitingShipmentItemsStatus"
	  }),
    async waitData(){
		await this.waitDatas({limit: 4, shippingStatus: 'Initiated', paymentStatus: 'Done'})
    },
    ...mapActions({
		shipDatas:"consolidateItems/shippingDoneItemsStatus"
	  }),
      async shipData(){
		  await this.shipDatas({limit: 4, shippingStatus: 'Completed', paymentStatus: 'Done'})
    },
  },
		components: {mdbContainer,mdbRow,mdbCol,mdbCard,mdbCardHeader,mdbCardBody,mdbCardTitle,mdbCardText,mdbCardFooter,
			mdbCardUp,mdbCardAvatar,mdbCardGroup,mdbBtn,mdbView,mdbMask,mdbIcon
		}
};
</script>
  
<style>
  .page{
    background-color: #ffffff;
  }
  .logo-image {
    top: 0;
    left: 0;
    width: 26rem; 
    height: 8rem;
    padding-left: 3.125rem;
    padding-top: 1.875rem;
    background-color: #ffffff;
    display: flex;
}
  .date-time-container {
    top: 4rem;
    right: 5.5rem;
    color: #E87613;
    font-family: sans-serif;
    font-size: xx-large;
    width: auto;
    height: auto;
    position:absolute;
}
  .cards_status{
    min-width: 24rem;
    width: fit-content;
    margin: 1.25rem;
    height: auto;
    background-color: #F8F8F8;
    border-radius: 0.75rem;
    padding: 1.125rem 0.5rem 1.2rem 0.625rem;
    box-shadow: none; 
  }
  .title1{
    padding-left:1rem;
    color: #225AA5;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .title2{
    padding-left:1rem;
    color: #FAC300;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .title3{
    padding-left:1rem;
    color: #FF9800;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .title4{
    padding-left:1rem;
    color: #4CAF50;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .title1_1{
    color: #225AA5;
    float: right;
    line-height: 1.5rem;
    font-size: 1.5rem;
    padding-top: 0.3rem;
    padding-right: 1rem; 
  }
  .title2_2{
    color: #FAC300;
    float: right;
    line-height: 1.5rem;
    padding-right: 1rem;
    font-size: 1.5rem;
    padding-top: 0.3rem;
  }
  .title3_3{
    color: #FF9800;
    float: right;
    line-height: 1.5rem;
    padding-right: 1rem;
    font-size: 1.5rem;
    padding-top: 0.3rem;
  }
  .title4_4{
    color: #4CAF50;
    float: right;
    line-height: 1.5rem;
    padding-right: 1rem;
    font-size: 1.5rem;
    padding-top: 0.3rem;
  }
  .cards_1_status{
    width: auto;
    height: max-content;
    margin: 1rem 0 0 0;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0.75rem;
  }
  .name{
    font-style: sans-serif;
    color: #000000;
    font-weight: bold;
    font-size:large;
  }
  .xyz{
    display: flex;
    flex-direction: row;
    color: #2763B4;
    font-size: medium;
    font-weight: bold;
  }
  .uvx{
    background-color: #F6F5FF;
    border-radius: 0.3rem;
    padding: 0.2rem;
  }
  .xyz>* {
    margin-right: 1rem;
  }
  .cust_status{
    font-size: medium;
    align-self: flex-end;
    display: flex;
    align-items: center;
    font-weight: 400;
  }
  .proavatar_status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #4180F1;
    color: white;
    font-size: large;
    margin-left: 0.5rem;
  }
  .item_status{
    font-size: large;
    align-self: flex-end;
    align-items: center;
    font-weight: 400;
  }
</style>